body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Roboto Slab", serif;
}
html {
  margin: 0;
}

.side-nav {
  background-color: #3f65b5;
  height: 100%;
  position: fixed;
  padding-top: 3em;
  box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
  left: 0;
}

.icon {
  font-size: 1.5em;
  color: #fff;
}

.nav-div {
  padding: 1em;
}

.nav-div-clicked {
  padding: 1em;
  background-color: #00e5ff;
  outline: none;
}

.nav-div:hover {
  cursor: pointer;
  background-color: #74a0cf;
}
.nav-div-clicked:hover {
  cursor: pointer;
}

.clicked {
  font-size: 1.5em;
  color: #007d98;
}

.content-block {
  margin-top: 0.75em;
}

h1 {
  margin-top: 1.25em;
}

h2 {
  margin-top: 1.15em;
}

h4 {
  margin-top: 1.15em;
}

#background-video {
  position: fixed;
  margin-top: 4em;
  max-width: 60vw;
  max-height: 75vh;
  opacity: 0.75;
  border-radius: 2px;
}

@media only screen and (max-width: 600px) {
  #background-video {
    margin-top: 10em;
    width: 75vw;
    height: 50vh;
  }
}

.greeting {
  position: fixed;
  bottom: 2em;
}

.nav-pills {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.nav-pills .nav-item.active {
  background-color: #3f65b5;
  color: white;
}

.nav-pills:hover {
  cursor: pointer;
}

.nav-pills .nav-item.active:hover {
  color: white;
}

.ethos-block {
  margin-top: 2em;
}
